 
import '@/@iconify/icons-bundle'
import gettext from '@/plugins/gettext'
import App from '@/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import '@fontsource/public-sans'
import router from '@/router'
import '@core/scss/template/index.scss'
import { VueMasonryPlugin } from 'vue-masonry'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import * as Sentry from '@sentry/vue'
import axios from '@axios'
import { OpenAPI } from './api'


declare global {
  interface Window {
    FIELD_SERVICE_API_URL: string
    SENTRY_DSN?: string
    SENTRY_ENVIRONMENT?: string
  }
}

OpenAPI.BASE = window.FIELD_SERVICE_API_URL

if (process.env && ['development', 'test'].includes(process.env.VUE_APP_ENV as string) && process.env.VUE_APP_FIELD_SERVICE_API_URL) {
  const api_url = new URL(process.env.VUE_APP_FIELD_SERVICE_API_URL)

  api_url.pathname = 'api/dashboard/config'
  axios.get(api_url.toString()).then(() => {
    if (process.env.VUE_APP_FIELD_SERVICE_API_URL)
      OpenAPI.BASE = process.env.VUE_APP_FIELD_SERVICE_API_URL
  }).catch(() => {
    console.log('Development backend not callable, using staging.')
  })
}

// Create vue app
const app = createApp(App)

// Setup sentry
const release = import.meta.env.VITE_SENTRY_RELEASE
const dsn = window.SENTRY_DSN
const environment = window.SENTRY_ENVIRONMENT

if (dsn) {
  Sentry.init({
    app,
    dsn,
    release,
    environment,
    integrations: [
      Sentry.httpClientIntegration(),
      Sentry.captureConsoleIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

// Use plugins
app.use(gettext)
app.use(VueMasonryPlugin)
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)

// Mount vue app
app.mount('#app')
